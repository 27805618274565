.App {
  text-align: center;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.bg {
  width: 100%;
}

.grx {
  width: 20vw;
  filter: invert(100%);
  cursor: url("./assets/images/grx-cursor.png"), pointer;
}
.grxcar {
  width: 25vw;
}

.jbluery {
  width: 17vw;
  cursor: url("./assets/images/jbluery-cursor.png"), pointer;
}

.jbluerymural {
  width: 35vw;
}

.henosis {
  width: 24vw;
}

.gopra {
  width: 10vw;
  filter: invert(100%);
}

.hickman {
  width: 10vw;
  filter: brightness(100);
}

.giorgio {
  width: 10vw;
  filter: brightness(100);
}

.benji {
  width: 10vw;
}

.dna {
  height: 10vw;
  filter: invert(100%);
}

.linkedin {
  width: 5vw;
  filter: invert(100%);
}

.enter {
  width: 20vw;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.innercontainer {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.538);
  border-radius: 1.3vw;
  width: 75vw;
  height: 40vw;
  color: white;
  overflow-y: scroll;
  &__script {
    width: 45vw;
  }
  &__nav {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: 2vw;
    height: 44vw;
    width: 72vw;
    &--info {
      background-color: rgba(227, 227, 227, 0.942);
      color: black;
      position: relative;
      width: 72vw;
      height: 5vw;
      border-radius: 1vw;
    }
  }
}

// .App-logo {
//   height: 40vmin;
//   pointer-events: none;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }

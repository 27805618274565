.bg-foot {
  width: 100%;
  box-sizing: border-box;
  margin: -3px;
}

.footercontainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.tagged {
  position: absolute;
  text-align: center;
  color: rgba(161, 83, 61, 0.921);
  font-size: 1.1vw;
  margin-top: -1.5vw;
  margin-bottom: 1.5vw;
}
